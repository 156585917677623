
import React, {useEffect, useState} from 'react';

import LoftyCarousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import {connect} from 'react-redux';
import html2pdf from 'html2pdf.js';
import LoftyDropdownComponent from '../../components/loftydropdowncomponent';
import LoftyCountDown from '../../components/loftycountdown';
import LoftyCartCarousel from '../../components/loftycartcarousel';

import { addLoftyToken, deleteLoftytoken } from '../../actions/auth.actions';

import {getAllproductossnywe } from '../../api/productossnywe.api'

import './paginaprincipal.scss';






const Ppaginaprincipal = ({
  match,
  history,
  loftytoken,
  loftyuser,
  addLoftyUserApp,
  deleteLoftytokenApp,
  addLoftyTokenApp,
  
}) => {

	const [loftyendpoint_673b5ffa9633993dfe9ef098, setLoftyEndpoint_673b5ffa9633993dfe9ef098] = useState([]);
	
useEffect(() => {
		const loftyHandleData = async () => {
			const loftyres_673b5ffa9633993dfe9ef098 = await getAllproductossnywe({ token: loftytoken || 'asdsa' });
			setLoftyEndpoint_673b5ffa9633993dfe9ef098(loftyres_673b5ffa9633993dfe9ef098.data);
		}
		loftyHandleData();
	}, []);



  
  
  
  

  
  return (
    <div id="id659ed866b0184704f908792d" >

      <p className="" id="iinz">
        <span>Hola Este es el mundo de la programación</span>
      </p>
      
        <div id="ito6" className="">
          
            <div>
              <LoftyCarousel
                responsive={{
                  superLargeDesktop: {
                    breakpoint: {max: 4000, min: 3000},
                    items: 5,
                  },
                  desktop: {
                    breakpoint: {max: 3000, min: 1024},
                    items: 3,
                  },
                  tablet: {
                    breakpoint: {max: 1024, min: 464},
                    items: 3,
                  },
                  mobile: {
                    breakpoint: {max: 464, min: 0},
                    items: 1,
                  },
                }}
                infinite
                autoPlay
              >
                {
                  loftyendpoint_673b5ffa9633993dfe9ef098.map((loftyitem) => (
                    <div key={loftyitem._id} className="" id="icaf">
                      
        <div className="" id="iqqg">
          
          <div className="" id="">
            {loftyitem.nombrelq2uf}
          </div>
          
        </div>
        
        <div className="" id="in8o">
          
          <div className="" id="">
            {loftyitem.preciom1xwq}
          </div>
          
        </div>
        
                    </div>
                  ))
                }
              </LoftyCarousel>
            </div>
            
        </div>
        
</div>
  );
}

const mapStateToProps = (state) => state.auth;

const mapDispatchToProps = (dispatch) => ({
 addLoftyTokenApp: (data) => dispatch(addLoftyToken(data)),
 deleteLoftytokenApp: () => dispatch(deleteLoftytoken()),
});


export default connect(mapStateToProps, mapDispatchToProps)(Ppaginaprincipal);
  